<template>
  <div>
    <v-card elevation="0" class="py-2">
      <v-card-title v-if="!formTitle"> {{ $t("ui_add_task") }} </v-card-title>
      <v-card-text>
        <Form
          v-if="fieldSettings"
          ref="form"
          :title="formTitle"
          :data="data"
          :btnSubmitText="$t('ui_save')"
          :fieldSettings="fieldSettings"
          :type="form.display.settings.type"
          :validation="validation"
          :layout="form.display.settings.layout"
          :widgets="form.display.settings.widgets"
          :tabs="form.display.settings.tabs"
          :fields="form.display.settings.fields"
          @onSave="onSave"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import api from "@/api";
import Form from "@/components/Form/Form.vue";
import { rights } from "@/data/rights";
import { formService } from "@/services/formService";
import formatLocale from "@/utils/formaters/formatLocale";
import { mapGetters, mapState } from "vuex";

export default {
  components: { Form },
  data() {
    return {
      form: {},
      statuses: [],
      data: {},
      fieldSettings: null,
      validation: {},
    };
  },
  computed: {
    ...mapState("core$common", [
      "node",
      "forms",
      "fields",
      "processes",
      "user",
      "users",
      "progress",
    ]),
    ...mapGetters("core$common", [
      "getStatusesByProcessKey",
      "getProcessByKey",
      "getProgressByProcessId",
    ]),
    ...mapGetters("core$rights", ["getRightAttrs"]),
    formTitle() {
      return this.form?.display?.text?.title;
    },
    defaultValues() {
      const displayFields = this.form?.display?.fields;
      if (!displayFields) return {};

      const result = {};
      for (let fieldName in displayFields) {
        result[fieldName] = displayFields[fieldName].value;
      }

      return result;
    },
  },
  methods: {
    init() {
      this.form = this.forms.find((form) => {
        return (
          form.type === "create" &&
          form.processes.some((f) => f.key === this.$route.params.processKey)
        );
      });
      this.statuses = this.getStatusesByProcessKey(
        this.$route.params.processKey
      );
      this.data = formService.deserialize({
        fields: this.fields,
        defaultValues: this.defaultValues,
      });
      this.validation = formService.getValidation(this.form.validators);
      this.fieldSettings = formService.getFieldSettings({
        form: this.form,
        fields: this.fields,
        statuses: this.statuses,
        users: this.users,
        visibleFields: this.getRightAttrs(rights.FieldsView),
        toLocaleMethod: formatLocale,
      });
    },
    onSave() {
      const process = this.getProcessByKey(this.$route.params.processKey);
      const formData = this.$refs.form.getFormData();
      const payload = formService.serialize(formData);
      payload["author_id"] = this.user.id;

      if (!payload.status_id) {
        const progress = this.getProgressByProcessId(process.progress_id);
        const defaultStatus = progress?.settings?.default_create_status_name;
        if (defaultStatus) {
          payload.status_id = progress.statuses.find(
            (status) => status.name === defaultStatus
          )?.id;
        }
      }

      api
        .request({
          name: "createTask",
          pathParam: { nodeId: this.node.id, processId: process.id },
          showAlert: true,
          payload,
        })
        .then(async (resp) => {
          if (formData.attachments.length) {
            await this.addAttachments(resp.data.data.id, formData.attachments);
          }
          this.$router.push({
            name: "TaskCard",
            params: { processKey: process.key, taskKey: resp.data.data.key },
          });
        });
    },
    addAttachments(taskId, files) {
      const requests = files.map((file) => {
        const formData = new FormData();
        formData.append("attachment", file);
        return api.request({
          name: "createAttachment",
          pathParam: { nodeId: this.node.id, taskId, type: "task" },
          payload: formData,
        });
      });
      return Promise.all(requests);
    },
  },
  created() {
    this.init();
  },
};
</script>
